import React, { ChangeEvent } from "react";
import './input.css';

interface NameProps {
    value: string;
    onChange: (value: string) => void;
    placeholder: string;
  }

const Name: React.FC<NameProps> = ({ placeholder, value, onChange }) =>{
    
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      };

    return( 
        <div className="input-container">
           <input className="input" placeholder={placeholder} value={value} onChange={handleInputChange}/>
        </div>
        )
}

export default Name