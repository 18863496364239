import React, { memo } from 'react';

function Success() {
  return (
    <div>
      <h1>Успех!</h1>
      <p>Логин работает!!!</p>
    </div>
  );
}

export default memo(Success);
