import React from "react"
import './second-header.css';

const SecondHeaderRegistration = () =>{
    return(
    <div className="header-container-sec">
     <h2 className="custom-heading-sec">Регистрация</h2>
    </div>
    )
}

export default SecondHeaderRegistration