import React from "react"

import RememberMe from "./remember-me"
import ForgetMe from "./forget-me"
import './forget.css';

const Forget = () =>{

    return (
    <div className="forget-container">
       
         <span><RememberMe/></span>
         <span><ForgetMe/></span>
       
    </div>
    )
  }

export default Forget