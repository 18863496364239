import React, { memo } from 'react';
import Login from './components/login';
import MainHeader from './components/main-header';
import Password from './components/password';
import Name from './components/name';
import PasswordChecked from './components/password-check';

import './RegistrationPage.css';
import { useUnit } from 'effector-react';
import { emailStore, errorStore, nameStore, passwordCheckStore, passwordStore, patronymicStore, registrationFx, setEmail, setError, setName, setPassword, setPasswordCheck, setPatronymic, setSurname, surnameStore } from './models/auth';
import ButtReg from './components/buttReg';
import SecondHeaderRegistration from './components/second-header-registration';
import AccountRegistration from './components/account-registration';
import { useNavigate } from 'react-router-dom';
import { User } from './models/user';

const RegistrationPage: React.FC = () => {
    const navigate = useNavigate();
  
    const [email, password, passwordCheck, error, name, surname, patronymic] = useUnit([
      emailStore,
      passwordStore,
      passwordCheckStore,
      errorStore,
      nameStore,
      surnameStore,
      patronymicStore,
      setEmail.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value),
      setName.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value),
      setSurname.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value),
      setPatronymic.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value),
      setPassword.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value),
      setPasswordCheck.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value),
      setError.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value)
    ]);
  
    const handleRegistrationSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
    
      try {
        // Выполняем регистрацию и ожидаем результат
        const registrationResult = await registrationFx({ email, password, name, surname, patronymic });
    
        if (registrationResult) {
          const userData: User = {
            email,
            name,
            surname,
            patronymic,
            role: 'Сотрудник'  // Предположим, что новый пользователь всегда получает роль 'user'
          };
    
          // Сохраняем данные пользователя в localStorage
          localStorage.setItem('user', JSON.stringify([
            userData.email,
            userData.name,
            userData.surname,
            userData.patronymic,
            userData.role
          ]));
    
          // Обновляем куки
          document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
          document.cookie = "userData=" + encodeURIComponent(JSON.stringify({ 
            email: userData.email,
            name: userData.name,
            surname: userData.surname,
            patronymic: userData.patronymic,
            role: userData.role
          })) + ";path=/";
    
          // Перенаправление на главную страницу после успешной регистрации
          window.location.href = `https://super-ellipse.ru/main`;
        } else {
          setError('Ошибка регистрации. Попробуйте снова.');
        }
      } catch (error) {
        console.error('Registration process failed:', error);
        setError('Произошла ошибка во время регистрации. Попробуйте снова.');
      }
    };
    

    const loginNavigate = (event: React.MouseEvent<HTMLDivElement>) => {
      navigate("/")
    };


   return (
   <div className="main">
    <div className="sec-main">
   <MainHeader/>
   <SecondHeaderRegistration/>
   <form onSubmit={handleRegistrationSubmit}>
      <Login value={email} onChange={setEmail}/>
      <Name placeholder='Имя' value={name} onChange={setName}/>
      <Name placeholder='Фамилия' value={surname} onChange={setSurname}/>
      <Name placeholder='Отчество' value={patronymic} onChange={setPatronymic}/>
      <Password value={password} onChange={setPassword}/>
      <PasswordChecked value={passwordCheck} onChange={setPasswordCheck}/>
      {error && <span className="error">{error}</span>}
      <ButtReg/>
      </form>
   <AccountRegistration onClick={loginNavigate}/>
   </div>
 </div>
 )
}

export default memo(RegistrationPage)
