import React, { ChangeEvent } from "react";
import './input.css';

interface LoginProps {
  value: string;
  onChange: (value: string) => void;
}

const Login: React.FC<LoginProps> = ({ value, onChange }) => {

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
    
    return( 
    <div className="input-container">
       <input
          className="input"
          placeholder='Email'
          value={value}
          onChange={handleInputChange}
        />
    </div>
    )
  }

  export default Login