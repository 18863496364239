import React from "react";
import './forget-me.css';

const ForgetMe = () =>{
    return (
    <div className="forget-me-container">    
       <span className="forget-me">Забыли пароль?</span>
    </div>
    )
}

export default ForgetMe