import React from "react"
import './main-header.css';
const MainHeader = () => {
  
    return (
    <div className="header-container">
      <h1 className="custom-heading">АО ОЭК</h1>
    </div>
    )
  }

  export default MainHeader