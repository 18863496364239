import React from "react";
import AccountEszhe from "./account-eszhe";
import Registration from "./registration";
import './account.css';

interface AccProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const Account: React.FC<AccProps> = ({ onClick }) => {
    return(
      <div className="account-container">
    <span><AccountEszhe/></span>
    <span onClick={onClick}><Registration/></span>
      </div> 
    )
}

export default Account