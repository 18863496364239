import axios from "axios"
import { getEnvVariable } from "./env.d";
import { BaseTaskResult } from "./models/BaseTaskResult";
import { setError } from "./models/auth";

const baseURL = getEnvVariable('REACT_APP_BASE_URL')

export function getData(searchKey: string) {
    return axios.get<BaseTaskResult>(`${baseURL}/users/${searchKey}`)
}

export async function login(email: string, password: string) {
  const params = new URLSearchParams();
  params.append('email', email);
  params.append('password', password);

  try {
      const response = await axios.post(
          `${baseURL}/login_api/user/login`,
          params,
          {
              headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
              }
          }
      );
      return response.data;
  } catch (error) {
      // Приведение типа 'unknown' к более специфичному типу
      if (axios.isAxiosError(error)) {
          if (error.response) {
              // Сервер ответил с кодом, который выходит за рамки 2xx
              console.log('Error status:', error.response.status);
              console.log('Error data:', error.response.data);
          } else if (error.request) {
              // Запрос был сделан, но ответа не было
              console.log('No response received:', error.request);
              setError("Что-то пошло не так при отправке запроса, повторите попытку чуть позже.");
          } else {
              // Ошибка при настройке запроса
              console.log('Error message:', error.message);
          }
      } else {
          // Ошибка не связана с Axios, можно обработать по-другому
          console.log('Unexpected error:', error);
      }
      // Вернуть null или другое значение по умолчанию, если запрос завершился с ошибкой
      return null;
  }
}



export async function registration(email: string, password: string, name: string, surname: string, patronymic: string){
    const params = new URLSearchParams();
    params.append('email', email);
    params.append('password', password);
    params.append('name', name);
    params.append('surname', surname);
    params.append('patronymic', patronymic);

    try{
    const response = await axios.post(
      `${baseURL}/registration_api/user/signup`,
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
      console.log(response.data)
      return response.data;
    } catch (error) {
      // Приведение типа 'unknown' к более специфичному типу
      if (axios.isAxiosError(error)) {
          if (error.response) {
              // Сервер ответил с кодом, который выходит за рамки 2xx
              console.log('Error status:', error.response.status);
              console.log('Error data:', error.response.data);
          } else if (error.request) {
              // Запрос был сделан, но ответа не было
              console.log('No response received:', error.request);
              setError("Что-то пошло не так при отправке запроса, повторите попытку чуть позже.");
          } else {
              // Ошибка при настройке запроса
              console.log('Error message:', error.message);
          }
      } else {
          // Ошибка не связана с Axios, можно обработать по-другому
          console.log('Unexpected error:', error);
      }
      // Вернуть null или другое значение по умолчанию, если запрос завершился с ошибкой
      return null;
  }
}