import React, { memo } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './LoginPage';
import Success from './Success';
import RegistrationPage from './RegistrationPage';
function App() {
  return (
    <Router basename="/login">
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/success" element={<Success />} />
        <Route path='/registration' element={<RegistrationPage/>} />
      </Routes>
    </Router>
  );
}

export default memo(App);