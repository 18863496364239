import React from "react";
import './account.css';
import AccountNeztzhe from "./account-netzhe";
import LoginText from "./login-text";

interface AccProps {
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const AccountRegistration: React.FC<AccProps> = ({ onClick }) => {
    return(
      <div className="account-container">
    <span><AccountNeztzhe/></span>
    <span onClick={onClick}><LoginText/></span>
      </div> 
    )
}

export default AccountRegistration