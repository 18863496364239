declare namespace NodeJS {
    interface ProcessEnv {
      REACT_APP_BASE_URL: string
    }
  }

  export const getEnvVariable = (key: string): string => {
    const value = process.env[key];
    if (!value) {
      throw new Error(`Environment variable ${key} is not defined`);
    }
    return value;
  };