import React from "react";
import './account-eszhe.css';

const AccountEszhe = () =>{
    return (
    <div className="account-eszhe-container">    
       <span className="account">Нет аккаунта?</span>
    </div>
    )
}


export default AccountEszhe

