
import './remember-me.css';
import OffIcon from './Property=off (2).svg';
import OnIcon from './Property=on (1).svg';
import React, { useState } from 'react';


const RememberMe = () => {
  const [checked, setChecked] = useState(false);

  const handleToggle = () => {
    setChecked(!checked);
  };

  return (
    <div className="remember-me-container" onClick={handleToggle}>
      <img 
        src={checked ? OnIcon : OffIcon} 
        alt="checkbox" 
        className="checkbox-icon"
      />
      <span className="remember-me">Запомнить меня</span>
    </div>
  );
};
export default RememberMe