// src/models/auth.ts
import { createEvent, createStore, createEffect } from 'effector';
import { login, registration } from '../NetworkServices';

export const setEmail = createEvent<string>();
export const setPassword = createEvent<string>();
export const setPasswordCheck = createEvent<string>();
export const setError = createEvent<string>();
export const setName = createEvent<string>();
export const setSurname = createEvent<string>();
export const setPatronymic = createEvent<string>();

export const emailStore = createStore<string>('').on(setEmail, (_, email) => email);
export const passwordStore = createStore<string>('').on(setPassword, (_, password) => password);
export const passwordCheckStore = createStore<string>('').on(setPasswordCheck, (_, passwordCheck) => passwordCheck);
export const errorStore = createStore<string>('').on(setError, (_, error) => error);

export const nameStore = createStore<string>('').on(setName, (_, name) => name);
export const surnameStore = createStore<string>('').on(setSurname, (_, surname) => surname);
export const patronymicStore = createStore<string>('').on(setPatronymic, (_, patronymic) => patronymic);

export const loginFx = createEffect(async ({ email, password }: { email: string, password: string }) => {
  try {
    const response = await login(email, password);
    if (response && response.user) {
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(response.user));
      console.log(JSON.stringify(response.user))
      return true;
    }
    
    return false;
  } catch (error) {
    console.error('Error during login:', error);
    return false;
  }
});

loginFx.fail.watch((error) => {
  setError('Ошибка входа. Проверьте правильность введенных данных.');
  console.error('Login error', error);
});

export const registrationFx = createEffect(async ({ email, password, name, surname, patronymic }: { email: string, password: string, name: string, surname: string, patronymic: string }) => {
  try {
    const response = await registration(email, password, name, surname, patronymic);
    if (response && response.login) {
      localStorage.setItem('user', JSON.stringify(response.login));
      console.log(JSON.stringify(response.login))
      return true;
    }
    
    return false;
  } catch (error) {
    console.error('Error during registration:', error);
    return false;
  }
});

registrationFx.fail.watch((error) => {
  setError('Ошибка регистрации. Проверьте правильность введенных данных.');
  console.error('Registration error', error);
});

