import React from "react";
import './registration.css';

const Registration = () => {
    return(
        <div className="registration-container">
            <span className="registration">Регистрация</span>
        </div>
    )
}

export default Registration