import React, { memo } from 'react';
import Account from './components/account';
import Butt from './components/butt';
import Forget from './components/forget';
import Login from './components/login';
import MainHeader from './components/main-header';
import Password from './components/password';
import SecondHeader from './components/second-header';
import './LoginPage.css';
import { useUnit } from 'effector-react';
import { emailStore, errorStore, loginFx, passwordStore, patronymicStore, setEmail, setError, setPassword } from './models/auth';
import { useNavigate } from 'react-router-dom';
import { User } from './models/user';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();

  const [email, password, error] = useUnit([
    emailStore,
    passwordStore,
    errorStore,
    setEmail.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value),
    setPassword.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value),
    setError.prepend((e: React.ChangeEvent<HTMLInputElement>) => e.target.value)
  ]);

  const regNavigate = (event: React.MouseEvent<HTMLDivElement>) => {
    navigate("/registration")
  };


  const handleLoginSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  
    try {
      // Выполняем вход и ожидаем результат
      const resultFlag = await loginFx({ email, password });
  
      // Проверяем результат аутентификации
      if (resultFlag) {
        let userData: User | null = null;
  
        // Получаем данные пользователя из localStorage
        const userStrings = localStorage.getItem('user');
        if (userStrings) {
          try {
            const parsedUser = JSON.parse(userStrings);
  
            // Проверяем, что данные корректно распарсились и содержат ожидаемые поля
            if (Array.isArray(parsedUser) && parsedUser.length >= 5) {
              userData = {
                email: parsedUser[0],
                name: parsedUser[1],
                surname: parsedUser[2],
                patronymic: parsedUser[3],
                role: parsedUser[4],
              };
              console.log(userData);
            } else {
              console.error('Invalid user data format.');
            }
          } catch (error) {
            console.error('Error parsing user data:', error);
          }
        }
  
        // Обновляем куки
        document.cookie = "userData=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
        document.cookie = "userData=" + encodeURIComponent(JSON.stringify({ 
          email: userData?.email,
          name: userData?.name,
          surname: userData?.surname,
          patronymic: userData?.patronymic,
          role: userData?.role
        })) + ";path=/";
  
        // Перенаправление на главную страницу
        window.location.href = `https://super-ellipse.ru/main`;
      } else {
        setError('Ошибка входа. Проверьте правильность введенных данных.');
      }
    } catch (error) {
      console.error('Login process failed:', error);
      setError('Произошла ошибка во время входа. Попробуйте снова.');
    }
  };
  
   return (
   <div className="main">
    <div className="sec-main">
      <MainHeader/>
      <SecondHeader/>
      <form onSubmit={handleLoginSubmit}>
      <Login value={email} onChange={setEmail}/>
      <Password value={password} onChange={setPassword}/>
      {error && <span className="error">{error}</span>}
      <Forget/>
      <Butt/>
      </form>
      <Account onClick={regNavigate}/>
   </div>
 </div>
 )
}

export default memo(LoginPage)
