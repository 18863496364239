import React from "react";
import './registration.css';

const LoginText = () => {
    return(
        <div className="registration-container">
            <span className="registration">Войти</span>
        </div>
    )
}

export default LoginText