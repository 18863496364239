
import React from 'react';
import './butt.css';

const ButtReg = () => {
    return <button className="button" >Зарегистрироваться </button>
  }
  
export default ButtReg  

/*const [checked, setChecked] = useState(false)

const handleToggle = () => {
  setChecked(!checked);
 }
 onClick={handleToggle}*/