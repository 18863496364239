
import React from 'react';
import './butt.css';

const Butt = () => {
    return <button className="button" >Войти </button>
  }
  
export default Butt  

/*const [checked, setChecked] = useState(false)

const handleToggle = () => {
  setChecked(!checked);
 }
 onClick={handleToggle}*/